import { documentOn } from "../shared/jquery-alternative";
documentOn('change', '.stockable_type', function(e) {
  const stockable_type = e.currentTarget.selectedOptions[0]?.value;
  if (stockable_type === 'Ingredient') {

    document.querySelectorAll('.menu_item_id').forEach(el => el.style.display = 'none');
    document.querySelectorAll('.ingredient_id').forEach(el => el.style.display = '');

  } else if (stockable_type === 'MenuItem') {
    document.querySelectorAll('.menu_item_id').forEach(el => el.style.display = '');
    document.querySelectorAll('.ingredient_id').forEach(el => el.style.display = 'none');
  }
});

document.addEventListener('DOMContentLoaded', function() {

  document.querySelector('#stock_purchases_relate_supplier')?.addEventListener('change', function() {
    if (this.checked) {
      document.querySelectorAll('.stock_purchases_relate_supplier_property').forEach(el => {
        el.style.display = ''
        el.querySelectorAll('input, select, textarea').forEach(inpt => inpt.classList.contains('readonly') ? inpt.readOnly = true : inpt.disabled = false)
        // el.querySelectorAll('input:not([readonly]), select:not([readonly]), textarea:not([readonly])').forEach(inpt => inpt.disabled = false)
      
       
      });
    } else {
      document.querySelectorAll('.stock_purchases_relate_supplier_property').forEach(el => {
        el.style.display = 'none'
        el.querySelectorAll('input, select, textarea').forEach(inpt => inpt.disabled = true)
        // el.querySelectorAll('input:not([readonly]), select:not([readonly]), textarea:not([readonly])').forEach(inpt => inpt.disabled = true)
      });
    }
  });



  document.querySelector('#stock_purchase_payment_mode')?.addEventListener('change', function() {
    var stock_purchases_similar_attributes = document.querySelector(".nested-fields.z-depth-1.stock_purchases_similar_attributes");
    if (this.value == 'Cheque') {
      stock_purchases_similar_attributes.style.display = '';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_cheque_attributes").style.display = '';
      document.querySelector(".nested-fields.z-depth-1.stock_purchases_credit_attributes").style.display = 'none';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_bank_deposit_attributes").style.display = 'none';
      document.querySelector(".nested-fields.z-depth-1.stock_others_attributes").style.display = 'none';
    } else if (this.value == 'Credit Card') {
      document.querySelector(".nested-fields.z-depth-1.stock_purchases_credit_attributes").style.display = '';
      stock_purchases_similar_attributes.style.display = 'none';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_bank_deposit_attributes").style.display = 'none';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_cheque_attributes").style.display = 'none';
      document.querySelector(".nested-fields.z-depth-1.stock_others_attributes").style.display = 'none';
    } else if (this.value == 'Bank Deposit') {
      stock_purchases_similar_attributes.style.display = '';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_bank_deposit_attributes").style.display = '';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_cheque_attributes").style.display = 'none';
      document.querySelector(".nested-fields.z-depth-1.stock_others_attributes").style.display = 'none';
      document.querySelector(".nested-fields.z-depth-1.stock_purchases_credit_attributes").style.display = 'none';
    } else {
      stock_purchases_similar_attributes.style.display = 'none';
      document.querySelector(".nested-fields.z-depth-1.stock_purchases_credit_attributes").style.display = 'none';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_bank_deposit_attributes").style.display = 'none';
      stock_purchases_similar_attributes.querySelector("div.stock_purchases_cheque_attributes").style.display = '';
      document.querySelector(".nested-fields.z-depth-1.stock_others_attributes").style.display = 'none';
    }
  });
});