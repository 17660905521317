import List from 'list.js';

document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('.search-list')) {
    document.querySelectorAll('.search-list').forEach((el) => {
      const options =
        { valueNames: el.dataset.optionValueNames.split(',') };

      new List(this, options);
    });
  }
});