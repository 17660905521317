import './shared/sentry';

import { getScript, height, documentOn } from './shared/jquery-alternative';
// import M from "./shared/materialize-global";
import "./shared/materialize-global";
import {Sidenav, FormSelect, Datepicker, Timepicker, Collapsible, Materialbox, Dropdown, Tabs, Modal, Carousel } from "@materializecss/materialize";

window.app_name = 'venue';

import RailsUjs from '@rails/ujs';
RailsUjs.start();


import LocalTime from 'local-time';
LocalTime.start();

import './manager/manager_charts';
import '../../vendor/assets/javascripts/social-share-button';

import './manager/stock_purchase';

import './manager/search-list';
import './manager/_locations';

import { notifyJsMessages } from './shared/mobile-communication';
import dsBridge from 'dsbridge';
import './shared/set-timezone';
import './shared/set-csrf';

import AOS from 'aos';

notifyJsMessages(); //don't wait for content to be loaded, see if messages exist and execute

document.addEventListener('DOMContentLoaded', function() {
  notifyJsMessages();

  AOS.init();
  let elems = document.querySelectorAll('.sidenav');
  if (elems.length > 0) Sidenav.init(elems);

  elems = document.querySelectorAll('select:not(.select2)');
  if (elems.length > 0) FormSelect.init(elems);

  // elems = document.querySelectorAll('.datepicker');
  // let options = {
  //   autoClose: true,
  //   defaultDate: new Date()
  // };
  // if (elems.length > 0) Datepicker.init(elems, options);

  elems = document.querySelectorAll('.timepicker');
  options = {
    autoClose: true,
    twelveHour: false
  };
  if (elems.length > 0) Timepicker.init(elems, options);

  elems = document.querySelectorAll('.collapsible');
  if (elems.length > 0) {
    Collapsible.init(elems);
  }

  elems = document.querySelectorAll('.materialboxed');
  if (elems.length > 0) Materialbox.init(elems);

  elems = document.querySelectorAll('.dropdown-trigger');
  if (elems.length > 0) Dropdown.init(elems);

  document.querySelectorAll('.menu_item_id').forEach(el => el.style.display = 'none');

  elems = document.querySelectorAll('.tabs');
  if (elems.length > 0) Tabs.init(elems, {});

  if (!!window.foodnerdApi || (!!window.webkit && !!window.webkit.messageHandlers.foodnerdApi) || !!window.originalPostMessage) {
    document.getElementsByClassName('attach-file').addEventListener('click', function(e) {
      e.preventDefault();
      const me = this;
      dsBridge.call('attachFile', 'image', function(file) {
        me.value = file;
        if (!!me.datasets.preview && document.querySelector(me.datasets.preview)) {
          document.querySelector(me.datasets.preview).value = file;
        }
      });
    });
  }

  document.querySelector('input.check-all')?.addEventListener('click', function(e) {
    e.target.closest('form').querySelectorAll('input[type=checkbox]').forEach((checkboxEl) => {
      checkboxEl.checked = e.target.checked;
    });
  });

  documentOn('click', '.collapsible.ajax .collapsible-header', function(e) {
    const collapsible = e.target.closest('.collapsible');
    const body = collapsible.querySelector('.collapsible-body');
    if (body.innerHTML.trim().length < 1) {
      body.innerHTML = 'Loading data...';
      let xhr = new XMLHttpRequest();
      xhr.open("GET", collapsible.datasets.url, true);
      xhr.onreadystatechange = function() {
        if (xhr.readyState == XMLHttpRequest.DONE) {
          body.innerHTML = xhr.response;
          let elems = this.querySelectorAll('.tabs');
          if (elems.length > 0) Tabs.init(elems, {});

          elems = document.querySelectorAll('.modal');
          if (elems.length > 0) Modal.init(elems, {});
        }
      };
      xhr.send();
    }
  });

  // doesn't work for document.addEventListener
  document.addEventListener('cocoon:after-insert', function() {
    FormSelect.init(document.querySelectorAll('select'));
  });
  let navTwo = document.querySelector('.navtwo');
  if (navTwo) {
    const stickyNavTop = navTwo.getBoundingClientRect().top + document.body.scrollTop;

    const stickyNav = function() {
      const scrollTop = document.body.scrollTop + 100;
      if (scrollTop > stickyNavTop) {
        navTwo.classList.add('sticky');
      } else {
        navTwo.classList.remove('sticky');;
      }
    };

    stickyNav();
    window.addEventListener('scroll', function() {
      stickyNav();
    });
  }

  if (document.querySelector('.pagination')) {
    window.addEventListener('scroll', function() {
      const url = document.querySelector('.pagination .next_page')?.getAttribute('href');
      if (url && (window.scrollTop > (height(document) - height(window) - 50))) {
        document.querySelectorAll('.pagination').forEach(el => {
          el.textContent = 'Please Wait...';
        });
        getScript(url);
      }
    });
    window.dispatchEvent(new Event('scroll'));;
  }

  Carousel.init(document.querySelectorAll('.carousel'), options);
  Carousel.init(document.querySelectorAll('.carousel.carousel-slider'), {
    fullWidth: true,
    indicators: true
  });

  window.setInterval((function() {
    document.querySelectorAll('.carousel-slider').forEach(elem => {
      var instance = Carousel.getInstance(elem);
      instance.next();
    });
  }), 7000);
  document.querySelectorAll('.moveNextCarousel').forEach(el => {
    el.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelectorAll('.carousel-slider ').forEach(elem => {
        var instance = Carousel.getInstance(elem);
        instance.next();
      });
    });
  });
  document.querySelectorAll('.movePrevCarousel').forEach(el => {
    el.addEventListener('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      document.querySelectorAll('.carousel-slider ').forEach(elem => {
        var instance = Carousel.getInstance(elem);
        instance.prev();
      });
    });
  });

  document.querySelectorAll('#card-alert .cancel-icon').forEach(el => {
    el.addEventListener('click', function(e) {
      let cardAlertEl = e.target.closest('#card-alert');
      cardAlertEl.classList.add('fadeOutHide');
      cardAlertEl.classList.remove('fadeOutShow');
    });
  });

  function showImage(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        let uploadEl = document.querySelector(".upload");
        uploadEl.setAttribute("src", e.target.result);
        uploadEl.style.width = '170px';
        uploadEl.style.height = '170px';
      };

      reader.readAsDataURL(input.files[0]);
    }
  }

  document.querySelector("#user-image")?.addEventListener("change", function(e) {
    showImage(e.target);
  });
});

//  elems = document.querySelectorAll('.materialboxed')
//  instances = M.Materialbox.init(elems, null)

