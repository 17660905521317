// import { M } from '@materializecss/materialize';

// if (!window.M) {
//   window.M = M;
// }

// export const Sidenav = M.Sidenav
// export const FormSelect = M.FormSelect
// export const Datepicker = M.Datepicker
// export const Timepicker = M.Timepicker
// export const Collapsible = M.Collapsible
// export const Materialbox = M.Materialbox
// export const Dropdown = M.Dropdown
// export const Tabs = M.Tabs
// export const Modal = M.Modal
// export const Carousel = M.Carousel
// export const Slider = M.Slider
// export const toast = M.toast
// export const Toast = M.Toast

// export default window.M;

import { FormSelect, Modal, Collapsible, Tabs} from "@materializecss/materialize";

if (!window.M) {
  window.M = {
    FormSelect,
    Modal,
    Collapsible,
    Tabs
  };
}

// import { Sidenav, FormSelect, Datepicker, Timepicker, Materialbox, Dropdown, Modal, Collapsible, Tabs, Carousel, Slider, Toast} from '@materializecss/materialize';
// export const toast = (options) => new Toast(options)
// export { Sidenav, FormSelect, Datepicker, Timepicker, Materialbox, Dropdown, Modal, Collapsible, Tabs, Carousel, Slider, Toast} from '@materializecss/materialize';
