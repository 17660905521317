document.addEventListener('DOMContentLoaded', function() {
  if (document.querySelector('#grid_f_completed_at_fr')) {
    document.querySelector('#grid_f_completed_at_fr').setAttribute('placeholder', 'From');
  }

  if (document.querySelector('#grid_f_completed_at_to')) {
    document.querySelector('#grid_f_completed_at_to').setAttribute('placeholder', 'To');
  }

  if (document.querySelector("#products_graph")) {
    const MenuItemChart = function(container_id, products, product_sales) {
      if (document.querySelector(`#${ container_id }`)) {
        import('highcharts').then(module => {
          const Highcharts = module.default;
          Highcharts.chart('products_bar_graph', {
            chart: {
              type: 'column'
            },
            title: {
              text: I18n.t('stats.menu_items')
            },
            xAxis: {
              categories: products
            },
            yAxis: {
              title: {
                text: I18n.t('stats.menu_items_small')
              }
            },
            series: [{
              name: I18n.t('stats.menu_items'),
              data: product_sales
            }
            ]
          }
          );

        });
      }
    };

    const products = JSON.parse(document.querySelector("#products_graph").value);
    MenuItemChart("products_bar_graph", products.xaxis, products.series);
    document.getElementById('top_products').addEventListener('change', function() {
      const option = this.value;
      const slug = document.querySelector("#location_slug").value;
      fetch(`/manager/locations/${ slug }/stats.json?top_products=${ option }`, {
        method: "GET",
        headers: {
          "Accept": "application/json"
        }
      })
      .then(response => response.json())
      .then(data => {
        document.getElementById('products_bar_graph').innerHTML = "";
        MenuItemChart("products_bar_graph", data.products.xaxis, data.products.series);
        console.log(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    });
  }

  if (document.querySelector("#products_graph2")) {

    const MenuItemChart = function(container_id, products, product_sales) {
      if (document.querySelectorAll(`#${ container_id }`)) {
        import('highcharts').then(module => {
          const Highcharts = module.default;

          Highcharts.chart('products_bar_graph2', {
            chart: {
              type: 'spline'
            },
            title: {
              text: I18n.t('sales_report.sales_report')
            },
            xAxis: {
              categories: products
            },
            yAxis: {
              title: {
                text: I18n.t('sales_report.total_sales')
              }
            },
            series: [{
              name: I18n.t('sales_report.time_period'),
              data: product_sales
            }
            ]
          }
          );
        });
      }
    };

    const products = JSON.parse(document.querySelector("#products_graph2").value);
    MenuItemChart("products_bar_graph2", products.xaxis, products.series);
    document.getElementById('sales_duration').addEventListener('change', function() {
      const option = this.value;
      const slug = document.querySelector("#location_slug2").value;
      fetch(`/manager/locations/${ slug }/sales_report.json?sales_duration=${ option }`, {
        method: "GET",
        headers: {
          "Accept": "application/json"
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        document.getElementById('products_bar_graph2').innerHTML = "";
        MenuItemChart("products_bar_graph2", data.sales_report.xaxis, data.sales_report.series);
        document.querySelector('#purchases').textContent = data.purchases;
        document.querySelector('#sales').textContent = data.sales;
        document.querySelector('#expenses').textContent = data.expenses;
        document.querySelector('#customers').textContent = data.customers;
      })
      .catch(error => {
        console.error('Error:', error);
      });
    });
  }
});


