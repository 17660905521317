window.SocialShareButton = {
  openUrl(url,popup) {
    if (popup === "true") {
      return window.open(url,'popup','height=500,width=500');
    } else {
      window.open(url);
      return false;
    }
  },

  share(el) {
    const site = $(el).data('site');
    const appkey = $(el).data('appkey') || '';
    const $parent = $(el).parent();
    let title = encodeURIComponent($(el).data(site + '-title') || $parent.data('title') || '');
    const img = encodeURIComponent($parent.data("img") || '');
    let url = encodeURIComponent($parent.data("url") || '');
    const via = encodeURIComponent($parent.data("via") || '');
    const desc = encodeURIComponent($parent.data("desc") || ' ');
    const popup = encodeURIComponent($parent.data("popup") || 'false');

    if (url.length === 0) {
      url = encodeURIComponent(location.href);
    }
    switch (site) {
      case "email":
        location.href = `mailto:?to=&subject=${title}&body=${url}`;
        break;
      case "weibo":
        SocialShareButton.openUrl(`http://service.weibo.com/share/share.php?url=${url}&type=3&pic=${img}&title=${title}&appkey=${appkey}`,popup);
        break;
      case "twitter":
        var via_str = '';
        if (via.length > 0) { via_str = `&via=${via}`; }
        SocialShareButton.openUrl(`https://twitter.com/intent/tweet?url=${url}&text=${title}${via_str}`,popup);
        break;
      case "douban":
        SocialShareButton.openUrl(`http://shuo.douban.com/!service/share?href=${url}&name=${title}&image=${img}&sel=${desc}`,popup);
        break;
      case "facebook":
        SocialShareButton.openUrl(`http://www.facebook.com/sharer.php?u=${url}`,popup);
        break;
      case "qq":
        SocialShareButton.openUrl(`http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&title=${title}&pics=${img}&summary=${desc}&site=${appkey}`, popup);
        break;
      case "tqq":
        SocialShareButton.openUrl(`http://share.v.t.qq.com/index.php?c=share&a=index&url=${url}&title=${title}&pic=${img}&appkey=${appkey}`, popup);
        break;
      case "baidu":
        SocialShareButton.openUrl(`http://hi.baidu.com/pub/show/share?url=${url}&title=${title}&content=${desc}`, popup);
        break;
      case "kaixin001":
        SocialShareButton.openUrl(`http://www.kaixin001.com/rest/records.php?url=${url}&content=${title}&style=11&pic=${img}&aid=${appkey}`, popup);
        break;
      case "renren":
        SocialShareButton.openUrl(`http://widget.renren.com/dialog/share?resourceUrl=${url}&srcUrl=${url}&title=${title}&pic=${img}&description=${desc}`, popup);
        break;
      case "google_plus":
        SocialShareButton.openUrl(`https://plus.google.com/share?url=${url}`, popup);
        break;
      case "google_bookmark":
        SocialShareButton.openUrl(`https://www.google.com/bookmarks/mark?op=edit&output=popup&bkmk=${url}&title=${title}`, popup);
        break;
      case "delicious":
        SocialShareButton.openUrl(`http://www.delicious.com/save?url=${url}&title=${title}&jump=yes&pic=${img}`, popup);
        break;
      case "plurk":
        SocialShareButton.openUrl(`http://www.plurk.com/?status=${title}: ${url}&qualifier=shares`, popup);
        break;
      case "pinterest":
        SocialShareButton.openUrl(`http://www.pinterest.com/pin/create/button/?url=${url}&media=${img}&description=${title}`, popup);
        break;
      case "linkedin":
        SocialShareButton.openUrl(`https://www.linkedin.com/shareArticle?url=${url}&title=${title}`, popup);
        break;
      case "tumblr":
        var get_tumblr_extra = function(param) {
          const cutom_data = $(el).attr(`data-${param}`);
          if (cutom_data) { return encodeURIComponent(cutom_data); }
        };

        var tumblr_params = function() {
          const path = get_tumblr_extra('type') || 'link';

          const params = (() => { switch (path) {
            case 'text':
              title = get_tumblr_extra('title') || title;
              return `title=${title}`;
            case 'photo':
              title = get_tumblr_extra('caption') || title;
              var source = get_tumblr_extra('source') || img;
              return `caption=${title}&source=${source}`;
            case 'quote':
              var quote = get_tumblr_extra('quote') || title;
              source = get_tumblr_extra('source') || '';
              return `quote=${quote}&source=${source}`;
            default: // actually, it's a link clause
              title = get_tumblr_extra('title') || title;
              url = get_tumblr_extra('url') || url;
              return `name=${title}&url=${url}`;
          } })();


          return `/${path}?${params}`;
        };

        SocialShareButton.openUrl(`http://www.tumblr.com/share${tumblr_params()}`, popup);
        break;
    }
    return false;
  }
};
